.heroChart {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 64px);
  top: 16px;
  z-index: 0;
}

@media screen and (min-width: 968px) {
  .heroChart {
    justify-content: flex-end;
    position: inherit;
    top: 0;
  }
}